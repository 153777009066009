<template>
    <div class="room-info" v-loading="loading">
        <div class="top">{{ data.roomName }}</div>
        <!-- <div class="qr">
            <div class="img-box">
                <div class="img-box-inner">
                
                </div>
            </div>
        </div> -->
        <div class="info panel">
            <div class="ti">
                <h3>基本信息</h3>
                <div class="status">
                    <template v-if="data.status !== ''">
                        <span v-if="data.status == 1 && data.subcontractorId == 0">待分配</span>
                        <span v-if="data.status == 1 && data.subcontractorId != 0" class="info">使用中</span>
                        <span v-if="data.status == 0" class="danger">停用</span>
                    </template>
                </div>
            </div>
            <div class="cont item-list">
                <div class="item">
                    <div class="label">项目名称</div>
                    <div class="text">{{ data.projectName }}</div>
                </div>
                <div class="item">
                    <div class="label">类型</div>
                    <div class="text">{{ data.type }}</div>
                </div>
                <div class="item">
                    <div class="label">房型</div>
                    <div class="text">{{ data.roomType }}</div>
                </div>
                <div class="item">
                    <div class="label">床位</div>
                    <div class="text"><template v-if="data.useBedCount !== ''">{{ data.useBedCount }}/{{ data.bedCount }}</template></div>
                </div>
                <div class="item">
                    <div class="label">分包单位</div>
                    <div class="text">{{ data.subcontractorName }}</div>
                </div>
                <div class="item">
                    <div class="label">负责人</div>
                    <div class="text">{{ data.supplierFuzeren }}</div>
                </div>
            </div>
        </div>
        <div class="people panel">
            <div class="ti">
                <h3>入住人员</h3>
            </div>
            <div class="cont people-list clearfix">
                <ul v-if="data.personList && data.personList.length > 0">
                    <li v-for="item in data.personList" :key="item.id">{{ item.name }}</li>
                </ul>
                <div v-else class="empty">暂无数据</div>
            </div>
        </div>
    </div>
</template>

<script>
import http from "@/utils/http"
import common from "@/utils/common"

export default {
    name: 'RoomInfoQR',
    components: {},
    data(){
        return {
            loading: false,
            data: {
                roomName : '',
                status: '',
                subcontractorId: '',

                projectName: '',
                type: '',
                roomType: '',
                useBedCount: '',
                bedCount: '',
                subcontractorName: '',
                supplierFuzeren: '',

                personList: [],
            },
        }
    },
    created() {
        this.init();
    },
    beforeCreate () {
        let body = document.querySelector('body');
        let arr = body.className.split(' ');
        let map = {}
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] !== '') {
                map[arr[i]] = 1;
            }
        }
        
        arr = []
        for (let item in map) {
            arr.push(item);
        }
        arr.push('grey-bg');

        body.className = arr.join(' ').replace(/^\s+|\s+$/g, '');
    },
    beforeDestroy () {
        let body = document.querySelector('body');
        let arr = body.className.split(' ');
        arr = arr.filter(x => x !== 'grey-bg');
        body.className = arr.join(' ').replace(/^\s+|\s+$/g, '').replace(/\s+/g, ' ');
    },
    methods: {
        ...common,
        init() {
            this.loadData();
        },
        loadData(){
            this.loading = true;
            let id = this.$route.query.id;

            http.get('v1/api/buildRoom/detail', { params: {id:id} }).then(req => {
                if (req.data.code == http.SUCCESS) {
                    let data = req.data.data;

                    this.data = {};
                    this.data.roomName = data.buildName + data.floorName + data.roomName;
                    this.data.status = data.status;
                    this.data.subcontractorId = data.supplierId;

                    this.data.projectName = !data.projectName ? '-' : data.projectName;
                    this.data.type = data.roomTypeName;
                    this.data.roomType = data.checkinNum + '人间';
                    this.data.useBedCount = data.checkin == null ? '0' : data.checkin;
                    this.data.bedCount = data.checkinNum;
                    this.data.subcontractorName = !data.supplierName ? '-' : data.supplierName;
                    this.data.supplierFuzeren = !data.supplierFuzeren ? '-' : data.supplierFuzeren;

                    this.data.personList = [];
                    data.workerList.forEach(item => {
                        this.data.personList.push({ id: item.id, name: item.name });
                    });
                } else {
                    this.showError(req.data.msg);
                }
            }).catch(err => {
                this.httpCatch(err);
            }).finally(() => {
                this.loading = false;
            });
        },
    }
};
</script>

<style scoped>
.room-info {
    padding-top: 6px;
    min-height: 100vh;
}

.room-info .top {
    margin: 0 5px;
    border-radius: 5px;
    height: 60px;
    line-height: 60px;
    background-color: #409eff;
    text-align: center;
    font-size: 22px;
    color: #fff;
}

.room-info .qr {
    margin: 6px auto 0;
    width: 70%;
    padding-bottom: 60%;
    background-color: #fff;
    border-radius: 5px;
    position: relative;
}

.room-info .img-box {
    position: absolute;
    top: 25px;
    right: 25px;
    bottom: 25px;
    left: 25px;
}

.room-info .img-box img {
    display: block;
    width: 100%;
    height: 100%;
    border: none;
}

.room-info .img-box-inner {
    margin: 0 auto;
    position: relative;
    width: calc(60vw - 50px);
    height: 100%;
    background-color: #f1f1f1;
}

.room-info .panel {
    margin: 6px 6px 0;
    padding: 0 10px;
    border-radius: 5px;
    background-color: #fff;
}

.room-info .panel .ti {
    padding-top: 6px;
    height: 32px;
    line-height: 32px;
    border-bottom: 1px solid #eee;
    position: relative;
}

.room-info .panel .ti .status {
    position: absolute;
    top: 3px;
    right: 0;
}

.room-info .panel .ti .status span {
    display: inline-block;
    border-width: 1px;
    border-style: solid;
    border-radius: 6px;
    padding: 0 5px 1px;
    line-height: 20px;
    font-size: 13px;

    background-color: #fff;
    border-color: #b3d8ff;
    color: #409eff;
}

.room-info .panel .ti .status span.success {
    background-color: #fff;
    border-color: #c2e7b0;
    color: #67c23a;
}

.room-info .panel .ti .status span.info {
    background-color: #fff;
    border-color: #d3d4d6;
    color: #909399;
}

.room-info .panel .ti .status span.danger {
    background-color: #fff;
    border-color: #fbc4c4;
    color: #f56c6c;
}

.room-info .panel .ti .status span.warning {
    background-color: #fff;
    border-color: #f5dab1;
    color: #e6a23c;
}

.room-info .panel .ti h3 {
    padding-left: 8px;
    font-size: 17px;
    font-weight: normal;
    color: #409eff;
}

.room-info .panel .cont {
    padding: 8px 8px 10px;
}

.room-info .panel .item-list,
.room-info .panel .people-list {
    padding-top: 2px;
    padding-bottom: 12px;
}

.room-info .item-list .item { 
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 8px;
}

.room-info .item-list .item .label,
.room-info .item-list .item .text {
    color: #444;
    font-size: 16px;
}

.room-info .people-list ul li {
    float: left;
    width: 33.333333%;
    color: #333;
    font-size: 16px;
    padding-top: 8px;
}

.room-info .empty {
    color: #bbb;
    text-align: center;
    padding-top: 8px;
}
</style>